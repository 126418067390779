import React from 'react';

import cn from './Banner.module.scss';

export const Banner = () => {
  return (
    <>
      <div className={cn.banner}>
        <h2 className={cn.subtitle}>AI Bringing You Brighter News</h2>
      </div>
    </>
  );
};
