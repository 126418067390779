import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal } from '../modal/Modal';
import { SubscribeCard } from '../subscribe-card/SubscribeCard';

import cn from './Header.module.scss';

export const Header: React.FC = () => {
  const [about_open, setAboutOpen] = useState(false);
  const [subscribe_open, setSubscribeOpen] = useState(false);
  const [top, setTop] = useState(0);
  const lastScrollTop = useRef(0);

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
      const headerHeight = document.getElementById('header')?.offsetHeight || 0;

      if (currentScroll <= 0) {
        setTop(0);
      } else if (currentScroll > lastScrollTop.current) {
        setTop((prev) => Math.min(prev + (currentScroll - lastScrollTop.current), headerHeight));
      } else {
        setTop((prev) => Math.max(prev - (lastScrollTop.current - currentScroll), 0));
      }

      lastScrollTop.current = currentScroll;
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (e.clientY < 50) {
        setSubscribeOpen(true);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setTimeout(() => {
        setSubscribeOpen(true);
      }, 6000);
    }

    window.addEventListener('scroll', handleScroll);
    setTimeout(() => {
      window.addEventListener('mousemove', handleMouseMove);
    }, 3000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const onClickPositivLogo = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div id="header" className={cn.header} style={{ top: `-${top}px` }}>
      <img className={cn.logo} src="/cognitiv-positiv-logo.png" alt="cognitiv positiv logo" onClick={onClickPositivLogo} />
      <div className={cn.menu}>
        <p className={cn.date}>{formattedDate}</p>
        <button className={cn.about} onClick={() => setAboutOpen(true)}>
          ABOUT
        </button>
        <button className={cn.subscribe} onClick={() => setSubscribeOpen(true)}>
          SUBSCRIBE
        </button>
      </div>
      <Modal open={subscribe_open} onClose={() => setSubscribeOpen(false)} background="#ffe21f">
        <SubscribeCard />
      </Modal>
      <Modal
        header={<img className={cn.logo_blue} src="/cognitiv-positiv-logo-blue.png" alt="cognitiv positiv logo" />}
        open={about_open}
        onClose={() => setAboutOpen(false)}
      >
        <p className={cn.description}>
          At More Positiv, we curate uplifting news, highlighting breakthroughs, discoveries, and inspiring stories. Powered by advanced AI, we
          analyze content sentiment to deliver the top positive stories each day. With the Positiv News Pulse, stay updated on the overall sentiment
          of online news daily. Join us in celebrating the brighter side of news!
        </p>
      </Modal>
    </div>
  );
};
