import React, { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import classNames from 'classnames';

import { GraphData } from '../PositivPulse';

import cn from './SentimentGraph.module.scss';

const MAX_SAMPLES = 15;

export enum DATE_RANGE {
  ALL_TIME,
  YEAR,
  MONTH,
  WEEK,
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const SentimentGraph = ({ title, icon, labels, events, data }: GraphData) => {
  const [date_range, setDateRange] = useState<DATE_RANGE>(DATE_RANGE.ALL_TIME);

  const filtered = useMemo(() => {
    const today = new Date();

    const isWithinRange = (label: string, rangeInDays: number) => {
      if (rangeInDays === Infinity) return true;

      const labelDate = new Date(label);
      const rangeStart = new Date();
      rangeStart.setDate(today.getDate() - rangeInDays);

      return labelDate >= rangeStart && labelDate <= today;
    };

    const range_in_days = (() => {
      switch (date_range) {
        case DATE_RANGE.WEEK:
          return 7;
        case DATE_RANGE.MONTH:
          return 30;
        case DATE_RANGE.YEAR:
          return 365;
        case DATE_RANGE.ALL_TIME:
        default:
          return Infinity;
      }
    })();

    const filtered_indexes = labels
      .map((label, index) => (isWithinRange(label, range_in_days) ? index : null))
      .filter((index) => index !== null) as number[];

    return {
      labels: filtered_indexes.map((index) => labels[index]),
      events: filtered_indexes.map((index) => events[index]),
      data: filtered_indexes.map((index) => data[index]),
    };
  }, [data, date_range, events, labels]);

  const graph_data = {
    labels: filtered.labels,
    datasets: [
      {
        data: filtered.data,
        fill: true,
        backgroundColor: 'rgba(255, 226, 31, 0.2)',
        borderColor: 'rgba(255, 226, 31, 1)',
        tension: 0.2,
        pointRadius: filtered.data.map((_, i) => (filtered.events[i] ? 5 : 0)),
        pointHoverRadius: filtered.data.map((_, i) => (filtered.events[i] ? 6 : 0)),
        pointBackgroundColor: 'rgba(255, 226, 31, 1)',
        pointBorderColor: 'rgba(0, 0, 0, 0.1)',
        pointBorderWidth: 2.5,
        pointHoverBorderWidth: 2.5,
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
      },
    ],
  };

  const scale_range = Math.max(...data) - Math.min(...data);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
        filter: (tooltipItem: any) => !!filtered.events[tooltipItem.dataIndex],
        callbacks: {
          title: (context: any) => filtered.labels[context.dataIndex],
          label: (context: any) => filtered.events[context.dataIndex],
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        padding: 12,
        titleColor: 'white',
        bodyColor: 'white',
        bodySpacing: 4,
        displayColors: false,
        animation: {
          duration: 100,
        },
      },
    },
    scales: {
      y: {
        min: Math.floor(Math.max(0, Math.min(...data) - scale_range)),
        max: Math.floor(Math.max(...data) + scale_range),
      },
      x: {
        ticks: {
          callback: function (_: any, index: number): any {
            const total = filtered.labels.length;
            const step = Math.ceil(total / MAX_SAMPLES);
            return index % step === 0 ? filtered.labels[index] : '';
          },
        },
      },
    },
  };

  return (
    <div className={cn.container}>
      <div className={cn.row}>
        <div className={cn.title}>
          <div className={cn.icon}>
            <FontAwesomeIcon icon={icon} />
          </div>
          <p>{title} Sentiment Over Time</p>
        </div>
        <div className={cn.date_ranges}>
          <div className={classNames(cn.date_range, { [cn.selected]: date_range === DATE_RANGE.WEEK })} onClick={() => setDateRange(DATE_RANGE.WEEK)}>
            1 Week
          </div>
          <div
            className={classNames(cn.date_range, { [cn.selected]: date_range === DATE_RANGE.MONTH })}
            onClick={() => setDateRange(DATE_RANGE.MONTH)}
          >
            1 Month
          </div>
          {/* <div className={classNames(cn.date_range, { [cn.selected]: date_range === DATE_RANGE.YEAR })} onClick={() => setDateRange(DATE_RANGE.YEAR)}>
            1 Year
          </div> */}
          <div
            className={classNames(cn.date_range, { [cn.selected]: date_range === DATE_RANGE.ALL_TIME })}
            onClick={() => setDateRange(DATE_RANGE.ALL_TIME)}
          >
            All Time
          </div>
        </div>
      </div>
      <div className={cn.graph}>
        <Line data={graph_data} options={options} />
      </div>
    </div>
  );
};
